import type { TUserProfile } from '@tl-lms/types/profile'

function updateUserProfile(payload: Record<string, any>) {
  const { fetchOnce } = useAuthFetch()
  const { data: user } = useAuth()
  if (!user.value) return null
  return fetchOnce<TUserProfile>(`/api/user-profiles/${user.value.id_slug}/`, 'PUT', payload)
}

type UseProfileReturnGettersType = {
  data: Ref<TUserProfile | null>
  status: ReturnType<typeof useFetch<TUserProfile>>['status']
  pending: ReturnType<typeof useFetch<TUserProfile>>['pending']
  error: ReturnType<typeof useFetch<TUserProfile>>['error']
}

type UseProfileReturnActionsType = {
  refresh: ReturnType<typeof useFetch<TUserProfile>>['refresh']
  updateUserProfile: typeof updateUserProfile
}

type UserProfileReturnType = UseProfileReturnGettersType & UseProfileReturnActionsType

export async function useProfile(): Promise<UserProfileReturnType | null> {
  const { status: authStatus, data: user, token } = useAuth()
  if (authStatus.value !== 'authenticated' || !user.value) {
    return null
  }
  const runtimeConfig = useRuntimeConfig()
  const baseURL = process.client
    ? runtimeConfig.public.clientApiBaseUrl
    : runtimeConfig.serverApiBaseUrl
  const fetchProfileResult = await useFetch<TUserProfile>(
    `/api/user-profiles/${user.value.id_slug}/`,
    {
      baseURL,
      method: 'GET',
      headers: {
        Authorization: token.value || ''
      }
    }
  )

  // TODO check this logic
  if (!fetchProfileResult.data && !fetchProfileResult.error) {
    // create profile
    updateUserProfile({
      first_name: user.value.first_name,
      last_name: user.value.last_name,
      email: user.value.email
    })?.then(() => {
      // Update the profile again to get the data
      fetchProfileResult.refresh()
    })
  }

  const getters: UseProfileReturnGettersType = {
    status: readonly(fetchProfileResult.status),
    data: readonly(fetchProfileResult.data),
    error: readonly(fetchProfileResult.error),
    pending: readonly(fetchProfileResult.pending)
  }
  const actions: UseProfileReturnActionsType = {
    // TODO check why we need force type casting here
    refresh: fetchProfileResult.refresh as ReturnType<typeof useFetch<TUserProfile>>['refresh'],
    updateUserProfile
  }

  return {
    ...getters,
    ...actions
  }
}
